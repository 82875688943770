import React from "react"
import { Card, CardTitle, CardBody, Form, FormGroup, CardText } from "reactstrap"
import { graphql } from "gatsby"
import { Link } from "gatsby"

//This is used for horizontal line
const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 3
      }}
  />
);

const ColoredThinLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);
const KitchenSidebar = () => (
  <div>
    <Card>
      <CardBody>
      <CardTitle className="text-center text-uppercase mb-3">
          <b>Recommended</b>
        </CardTitle>

        <Form className="text-center">
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/small-kitchen-design" activeStyle={{ color: "red" }}>छोटा किचन की डिज़ाइन कैसे करें</Link>
          </FormGroup>
          <ColoredThinLine/>

          <FormGroup>
          <Link to="/must-read-before-modular-kitchen-design" activeStyle={{ color: "red" }}>किचन डिज़ाइन करने से पहले किन किन बात को ध्यान में रखने की जरुरत है ?</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/kitchen-color-combination" activeStyle={{ color: "red" }}>मॉडुलर किचन - कलर कैसे चुने ?</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="/solidwood-vs-plywood" activeStyle={{ color: "red" }}>Solidwood vs Plywood कौन सा अच्छा है?</Link>
          </FormGroup>
        </Form>
        
        <ColoredLine color="red" />
        <Form className="text-center" color='gray'></Form>

        
        <Form className="text-center">
          {/* <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder="Your email address.."
            />
          </FormGroup>
          <button className="btn btn-outline-success text=uppercase">
            Subscribe
          </button> */}

        </Form>
        <Form className="text-center" color='gray'>

        <CardTitle className="text-center text-uppercase mb-3">
          <b>Video reference links</b>
        </CardTitle>
         {/* <FormGroup>
          <div className="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/ne_ldHj1BNU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
        </FormGroup>

        <ColoredThinLine/>
        <FormGroup>
        <div className="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/J67m8QGWYRA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
        </FormGroup> */}
          <FormGroup>
          <Link to="https://youtu.be/ne_ldHj1BNU" activeStyle={{ color: "red" }}>False ceiling कराने से पहले ये वीडियो जरूर देखे</Link>
          </FormGroup>
          <ColoredThinLine/>
          <FormGroup>
          <Link to="https://youtu.be/J67m8QGWYRA" activeStyle={{ color: "red" }}>Gyspum vs POP False ceiling </Link>
          </FormGroup>
          <ColoredThinLine/>
          
        </Form>
        {/* <Link to="/tags" activeStyle={{ color: "red" }}>Tags </Link> */}
        <tagPost/>
        <tagPage/>
        <ColoredLine color="red" />

        <CardTitle className="text-center text-uppercase mb-3">
        <b>Kitchen Calculator</b>
        </CardTitle>
        <button className="btn btn-outline-success text=uppercase" >
        Kitchen
          </button>
        
      </CardBody>
    </Card>
  </div>
)

const sidebarQuery = graphql`
  query sidebarQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default KitchenSidebar
