import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import KitchenCalculator from "./kitchenCalculator"
import { Container, Row, Col } from "react-bootstrap"
import Sidebar from "../components/kitchensidebar"
const Kitchen = () => (
  <Layout>
    <SEO
      title="किचन डिज़ाइन"
      keywords={[
        "Nano White",
        "Toughend glass",
        "Small Kitchen Design",
        "Modular Kitchen Design kaise kare",
        "Luxury Interiors",
        "Kitchen Calculator",
        "False Ceiling Calculator",
        "Kitchen ke liye wood selection kaise kare",
        "Chota kitchen design kaise kare",
        "Modular kitchen color kaise chune",
        "Kitchen design karne se pahle kin kin baato ko dhyan me rakhe",
        "tags",
        "Pramri Interiors tages",
        "false ceiling",
        "Tips for false ceiling",
        "Interior Design",
        "Interior Design planning",
        "Gypsum vs POP",
        "Different types of False Ceiling",
        "Modular Kitchen",
        "Kitchen Design",
        "House Painting",
        "Kitchen Laminates",
        "PVC Laminates",
        "Laminates Selection",
        "Small house Design",
        "Small master bedroom design",
        "Master Bedroom design",
        "Woodwork",
        "Home Interiors",
        "tags",
        "Pramri Interiors tags",
        "false ceiling in hindi",
        "Tips for false ceiling in hindi",
        "Interior Design in hindi",
        "Interior Design planning in hindi",
        "Gypsum vs POP in hindi ",
        "Different types of False Ceiling in hindi",
        "Modular Kitchen in hindi",
        "Kitchen Design in hindi ",
        "House Painting in hindi",
        "Kitchen Laminates in hindi",
        "PVC Laminates in hindi",
        "Laminates Selection in hindi",
        "Small house Design in hindi",
        "Small master bedroom design in hindi",
        "Master Bedroom design in hindi",
        "Woodwork in hindi",
        "Home Interiors in hindi" 
      ]}
    />
    <Container>
      {/* <Row>
      <Col lg={6}>
       Hi Test
      </Col>
      <Col lg={6}>
        <p><strong>Kitchen t</strong></p>
        <div className="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/-JY9dfVo5dQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
      </Col>
    </Row> */}
      {/* <h1>Kitchen Calculator</h1> */}
      {/* <Row><KitchenCalculator/></Row> */}
      <h2>Recommended Sink</h2>
      <p></p>
      <Row>
        <Col lg={6}>
          ये Sink Franke कंपनी का है वैसे तो इस कंपनी का Sink काफी अच्छा होता है
          लेकिन जो ये Sink आप देख रहे हैं ये काफी मॉडर्न और अट्रैक्टिव दीखता है
          और ये स्पेस सेविंग है Ye Sink काफी अच्छी क्वालिटी का है ये छोटे किचन
          के लिए रेकमेंड किया जाता है ये अलग अलग साइज(32x18 , 24x18 ,21x18) में
          आता है और किचन की साइज के अनुसार Sink की साइज को रेकमेंड किया जाता है
          मैं इस सिंक को 5 साल से इस्तेमाल कर रहा हूँ मुझे इस्तेमाल करते समय ये
          काफी अच्छा लगा क्लीनिंग बहुत ही इजी होता है आज भी देखेंगे तो ये नया
          दीखता है अगर आपका किचन का साइज बड़ा है तो आप 32x18 इंच के लिए जा सकते
          हैं वैसे स्टैण्डर्ड साइज आप लेना चाहते हैं तो 24x18 का ले सकते हैं अगर
          किचन का साइज ज्यादा छोटा हो तो 21x18 वाला भी ले सकते हैं
          <p>
            <b>सुझाव</b> - आप जब भी किचन कॉउंटरटॉप का काम कर रहें हैं तो सिंक को
            पहले से खरीद के जो भी ग्रेनाइट इनस्टॉल करने वाले हैं उनसे चेक कर ले
            क्योकि हर घर की किचन की डिज़ाइन अलग हो सकती है और वो लोग अच्छे से
            इनस्टॉल कर सके{" "}
          </p>
        </Col>
        <Col lg={3}>
          <div>
            <iframe
              marginwidth="0"
              width="560"
              height="315"
              frameborder="0"
              src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=pramri-21&marketplace=amazon&amp;region=IN&placement=B07N9DM9LN&asins=B07N9DM9LN&linkId=83f84e1a255f390186dd6adbd6661de9&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF"
            ></iframe>
          </div>
          <div>
            <iframe
              marginwidth="0"
              width="560"
              height="315"
              frameborder="0"
              src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=pramri-21&marketplace=amazon&amp;region=IN&placement=B07N9BXCM7&asins=B07N9BXCM7&linkId=93dff0efeffbaed57753a24c1bf60256&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
            ></iframe>
          </div>
          <div>
            <iframe
              marginwidth="0"
              width="560"
              height="315"
              frameborder="0"
              src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=pramri-21&marketplace=amazon&amp;region=IN&placement=B07N9JKKYQ&asins=B07N9JKKYQ&linkId=08929391bed106207c3bb7a365524c77&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
            ></iframe>
          </div>
        </Col>
        <Col lg={3}>
        <Sidebar/>
        </Col>
      </Row>
      
      {/* <h2>Recommended Chimney</h2>
      <Row>
        <Col lg={6}>
        Best Chimney 
        </Col>
        <Col lg={2}>

        </Col>
      </Row> */}
    </Container>
  </Layout>
)

export default Kitchen
